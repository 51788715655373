import React from "react";

function Contact() {
  return (
    <section id="contact">
      <h2>Contact Me</h2>
      <p>
        If you'd like to get in touch with me, you can reach out via email at
        bijukchhekunja@gmail.com.
      </p>
    </section>
  );
}

export default Contact;
