import React from "react";

function Education() {
  return (
    <section id="education">
      <h2>Education</h2>
      <div class="education-container">
        <div className="education-item">
          <h3>Bachelor of Computer Science(Hons.)</h3>
          <p>Infrastructure University Kuala Lumpur (IUKL)</p>
          <p>Kuala Lumpur, Malaysia</p>
          <p>2022</p>
        </div>
        <div className="education-item">
          <h3>Intermediate Level (+2)</h3>
          <p>Kumudini Kunja Higher Secondary School</p>
          <p>Kathmandu, Nepal</p>
          <p>2018</p>
        </div>
      </div>
    </section>
  );
}

export default Education;
