import React from "react";

function About() {
  return (
    <section id="about">
      <h2>About Me</h2>
      <p>
        I am an experienced software developer with a diverse skill set and a
        passion for building innovative solutions. Currently, I work as a
        full-time software developer at Aerial Arts LLC, where I am involved in
        developing and maintaining high-quality software applications.
        Previously, I gained valuable experience as a full-time cloud worker at
        CloudFactory, specializing in image annotation and data entry. During
        this time, I worked remotely and enhancing my attention to detail and
        data processing skills. Additionally, I completed an internship as a
        research engineer at Brainycube Research Organization, which allowed me
        to deepen my understanding of Internet of Things (IoT) projects.
      </p>
      <p>
        I am highly motivated, adaptable, and continuously strive to stay
        updated with the latest technologies and industry trends. With my
        diverse background and passion for innovation, I am dedicated to
        delivering outstanding results and contributing to the success of
        software development projects.
      </p>
    </section>
  );
}

export default About;
