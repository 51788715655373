import React from "react";
import Header from "./components/Header";
import About from "./components/About";
// import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Experience from "./components/Experience";
import Education from "./components/Education";
import Skills from "./components/Skills";
import "./index.css";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <div className="App">
      <Analytics></Analytics>
      <Header />
      <About />
      {/* <Portfolio /> */}

      <Skills />
      <Education />
      <Experience />
      <Contact />
    </div>
  );
}

export default App;
