import React from "react";

function Experience() {
  return (
    <section id="experience">
      <h2>Experience</h2>
      <div class="experience-container">
        <div className="experience-item">
          <h3>Software Developer</h3>
          <p>Aerial Arts LLC</p>
          <p>Seattle, USA</p>
          <p>January 2022 to present</p>
        </div>
        <div className="experience-item">
          <h3>Technical Engineer</h3>
          <p>Elephanti Soft UG (haftungsbeschränkt)</p>
          <p>Stuttgart, Germany</p>
          <p>September 2022 to present</p>
        </div>
        <div className="experience-item">
          <h3>Cloudwoker</h3>
          <p>CloudFactory</p>
          <p>Lalitpur, Nepal</p>
          <p>February 2020 to December 2021</p>
        </div>
      </div>
    </section>
  );
}

export default Experience;
