import React from "react";

function Skills() {
  const skillsList = [
    "Java",
    "Python",
    "Django",
    "AWS",
    "React",
    "Spring Boot",
    "Jenkins",
    "Devops",
  ];

  return (
    <section id="skills">
      <h2>Skills</h2>
      <ul className="skills-list">
        {skillsList.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    </section>
  );
}

export default Skills;
